import React from 'react';
import { Container, Row, Col, Nav } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const Footer = () => {
  const { t, i18n } = useTranslation();
  const currentLang = i18n.language; // Get the current language from i18n

  return (
    <footer style={{ backgroundColor: '#f8f9fa', padding: '20px 0', marginTop: '50px' }}>
      <Container>
        <Row>
          <Col>
            <Nav className="justify-content-center">
              <Nav.Item>
                <Nav.Link as={Link} to={`/${currentLang}`}>Home</Nav.Link>
              </Nav.Item>                
              <Nav.Item>
                <Nav.Link as={Link} to={`/${currentLang}/faq`}>FAQ</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link as={Link} to={`/${currentLang}/privacy-policy`}>Privacy Policy</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link as={Link} to={`/${currentLang}/about-us`}>About</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link as={Link} to={`/${currentLang}/terms-and-conditions`}>Terms & Conditions</Nav.Link>
              </Nav.Item>
            </Nav>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
