import React from 'react';
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import FAQPage from './FAQPage';
import WheelPage from './WheelPage';
import Footer from './components/Footer';
import PrivacyPolicyPage from './PrivacyPolicyPage';
import TermsPage from './TermsPage';
import AboutUsPage from './AboutUsPage';
import Header from './components/Header';

function App() {
  return (
    <Router>
      <Header />
      <Routes>
        {/* Redirect / to /en */}
        <Route path="/" element={<Navigate to="/en" />}/>

        {/* FAQ */}
        <Route path="/faq" element={<Navigate to="/en/faq" />} />
        <Route path="/:lang/faq" element={<FAQPage/>} />
        {/* privacy policy */}
        <Route path="/privacy-policy" element={<Navigate to="/en/privacy-policy" />} />
        <Route path="/:lang/privacy-policy" element={<PrivacyPolicyPage/>} />
        {/* terms and conditions */}
        <Route path="/terms-and-conditions" element={<Navigate to="/en/terms-and-conditions" />} />
        <Route path="/:lang/terms-and-conditions" element={<TermsPage/>} />
        {/* about us */}
        <Route path="/about-us" element={<Navigate to="/en/about-us" />} />
        <Route path="/:lang/about-us" element={<AboutUsPage/>} />


        <Route path="/:lang/:wheelNameParam?" element={<WheelPage/>}/>

        <Route path="*" element={<Navigate to="/en" />} />
      </Routes>
      <Footer />
    </Router>
  );
}

export default App;
