import React, { useState } from 'react';
import { Nav, Button, Table, FormControl } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

const App = ({
    results,
    setResults,
    entries,
    setEntries,
    handleSpin,
    mustSpin,
}: {
    results: string[],
    setResults: (results: string[]) => void,
    entries: string[],
    setEntries: (entries: string[]) => void,
    handleSpin: () => void,
    mustSpin: boolean,
}) => {
  const [activeKey, setActiveKey] = useState('entries');
  const {t} = useTranslation();

  const handleReset = () => {
    setResults([]);
  };

  const handleTextAreaChange = (e: { target: { value: string; }; }) => {
    setEntries(e.target.value.split('\n'))
  }

  return (
    <div className="container mt-4">
      {/* Nav Pills */}
      <Nav variant="pills" activeKey={activeKey} onSelect={(selectedKey) => setActiveKey(selectedKey || '')} className="mb-3">
        <Nav.Item>
          <Nav.Link eventKey="entries">{t("tab_entries")}</Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link eventKey="results">{t("tab_results")}</Nav.Link>
        </Nav.Item>
      </Nav>

      {/* Conditional Rendering Based on Selected Tab */}
      {activeKey === 'entries' && (
        <FormControl
          as="textarea"
          rows={5}
          value={entries.join('\n')}
          className="mb-3"
          onChange={handleTextAreaChange}
        />
      )}

      {activeKey === 'results' && (
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>#</th>
              <th>Name</th>
            </tr>
          </thead>
          <tbody>
            {results.map((name, index) => (
              <tr key={index}>
                <td>{index + 1}</td>
                <td>{name}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}

      {/* Spin and Reset Buttons */}
      <div className="d-flex justify-content-between">
        <Button variant="primary" onClick={handleSpin} disabled={mustSpin}>
          {t("button_spin")}
        </Button>
        <Button variant="primary" onClick={handleReset}>
          {t("button_reset")}
        </Button>
      </div>
    </div>
  );
};

export default App;

