import React, { useEffect, useState } from "react";
import {
  Link,
  useNavigate,
  useParams,
} from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Wheel } from "react-custom-roulette";
import { Helmet } from "react-helmet-async";
import { Button, Col, Container, Modal, Row } from "react-bootstrap";
import Entries from "./components/Entries";
import { VALID_LANGUAGES, VALID_WHEEL_NAMES } from "./constant";
import "./wheelPage.css"

const WheelPage = () => {
  const {i18n } = useTranslation();
  const {lang, wheelNameParam} = useParams();
  const [mustSpin, setMustSpin] = useState(false);
  const [prizeNumber, setPrizeNumber] = useState(0);
  const wheelName = wheelNameParam ?? 'default';
  const {t} = useTranslation();
  const [entries, setEntries] = useState<string[]>([]);
  const [results, setResults] = useState<string[]>([]);
  const options = entries.map((entry: string) => {
      return {
        option: entry,
      };
    }
  )
  const spinDuration = 0.5; // Duration for the spin animation
  const [isLoading, setIsLoading] = useState(true);
  const [showResultModal, setShowResultModal] = useState(false);
  const [winner, setWinner] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    if (lang && VALID_LANGUAGES.includes(lang)) {
      if (wheelNameParam !== 'default' && VALID_WHEEL_NAMES.includes(wheelName) ) {
          i18n.changeLanguage(lang).then(() => {
            setEntries(t(`${wheelName}.default_entries`).split(', '));
            setIsLoading(false);
          })
      } else {
        navigate(`/${lang}`);
      }
    } else {
      navigate('/en');
    }
  }, [i18n, lang, navigate, wheelName])

  const handleCloseResultModal = () => setShowResultModal(false);

  const handleSpin = () => {
    if (!mustSpin) {  // Prevent multiple spins at the same time
      const randomPrize = Math.floor(Math.random() * options.length);
      setPrizeNumber(randomPrize);
      setMustSpin(true);
    }
  };

  const handleStopSpinning = () => {
    setMustSpin(false);
    const winner = entries[prizeNumber]
    setWinner(winner);
    setResults([...results, winner]);
    setShowResultModal(true);
  };
  
  if (isLoading) {
    return <>loading...</>
  }

  // Helper function to convert \n to line breaks
  const renderDescriptionWithLineBreaks = (text: string) => {
    return text.split("\n").map((line, index) => (
      <React.Fragment key={index}>
        {line}
        <br />
      </React.Fragment>
    ));
  };

  // Generate hreflang links dynamically
  const generateHrefLangLinks = () => {
    return VALID_LANGUAGES.map((langCode) => {
      // If the wheelName is 'default', simplify the URL to exclude 'default'
      const url = wheelName === 'default' 
        ? `https://spiin.me/${langCode}` // No 'default' in the URL
        : `https://spiin.me/${langCode}/${wheelName}`; // For other wheels
      return (
        <link key={langCode} rel="alternate" hrefLang={langCode} href={url} />
      );
    });
  };

  return (
    <>
      <Helmet>
        <title>{t(`${wheelName}.title`)}</title>
        <meta name="description" content={t(`${wheelName}.meta_description`)} />
        <meta name="title" content={t(`${wheelName}.meta_title`)} />
        <meta name="keywords" content={t(`${wheelName}.meta_keywords`)} />
        {/* This is to deindex /default page that are indexed due to legacy issues */}
        {wheelNameParam === 'default' && ( 
          <meta name="robots" content="noindex" />
        )}
        {/* Canonical link for /default to point to /en */}
        {wheelName === 'default' && (
          <link rel="canonical" href={`https://spiin.me/${lang}`} />
        )}
        {/* Dynamically generate hreflang links */}
        {generateHrefLangLinks()} 

        {/* Google Analytics script */}
        <script async src="https://www.googletagmanager.com/gtag/js?id=G-01WZPXST5F"></script>
        <script>
          {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', 'G-01WZPXST5F');
          `}
        </script>
      </Helmet>
      <div style={{ textAlign: "center", marginTop: "50px" }}>

          <h1>{t(`${wheelName}.title`)}</h1>

          <Container>
              <Row>
                {options.length > 0 &&
                  <Col lg={7} className="d-flex justify-content-center" >
                    <div onClick={handleSpin} className="wheel-wrapper"> {/* Added click event to the roulette */}
                      <Wheel
                          mustStartSpinning={mustSpin}
                          prizeNumber={prizeNumber}
                          data={options}
                          onStopSpinning={handleStopSpinning} // Handle stopping event
                          spinDuration={spinDuration} // Spin duration control
                          backgroundColors={["#FB7F7F", "#FBE07F", "#87A7F8"]}
                          textColors={["#161618"]}
                          outerBorderColor="#F8F9FA"
                          outerBorderWidth={1}
                          radiusLineColor="#F8F9FA"
                          radiusLineWidth={1}
                          pointerProps={{src: `${process.env.PUBLIC_URL}/pointer.svg`}}
                      />
                    </div>
                  </Col>
                }
                <Col lg={5}>              
                  <Entries 
                    results={results} 
                    setResults={setResults}
                    entries={entries}
                    setEntries={setEntries}
                    handleSpin={handleSpin}
                    mustSpin={mustSpin}
                  />
                </Col>
              </Row>
          </Container>

          <Modal show={showResultModal} onHide={handleCloseResultModal} centered dialogClassName="modal-90w">
            <Modal.Header closeButton>
              <Modal.Title>The winner is</Modal.Title>
            </Modal.Header>
            <Modal.Body>{winner}</Modal.Body>
            <Modal.Footer>
              <Button variant="primary" onClick={handleCloseResultModal}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>

          {/* Description with left alignment */}
          <div style={{ textAlign: "left", marginTop: "20px" , marginLeft: "10%", marginRight: "10%"}}>
            {renderDescriptionWithLineBreaks(t(`${wheelName}.description`))}
          </div>

          {/* List of Ready-Made Wheels */}
          <div style={{ marginTop: "20px" }}>
            <h3>{t("ready_made_wheels_title")}</h3>
            <ul style={{ listStyleType: "none", padding: 0 }}>
              {VALID_WHEEL_NAMES.map((name) => (
                <li key={name} style={{ marginBottom: "10px" }}>
                  <Link to={`/${lang}${name === 'default' ? '' : `/${name}`}`}>{t(`${name}.title`) || name}</Link>
                </li>
              ))}
            </ul>
          </div>          
      </div>
    </>
  );
};

export default WheelPage;