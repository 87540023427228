// Header.tsx
import React from 'react';
import { Container, Navbar, Nav, Dropdown, DropdownButton } from 'react-bootstrap';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const Header = () => {
  const { i18n } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation(); // Get the current path
  const currentLang = i18n.language; // Get the current language

  // Handle language change and preserve the current path
  const handleLanguageChange = (selectedLang: string | null) => {
    if (selectedLang) {
      // Extract the current pathname (e.g., '/es/yes-or-no') and replace the language
      const newPath = location.pathname.replace(`/${currentLang}`, `/${selectedLang}`);
      // Navigate to the new language version of the same path
      navigate(newPath);
      i18n.changeLanguage(selectedLang); // Change i18n language
    }
  };

  return (
    <Navbar bg="light" expand="lg" className="mb-4">
      <Container>
        {/* Left side: Logo with Name */}
        <Navbar.Brand as={Link} to={`/${currentLang}`}>
          <img
            src="/logo_with_name.png"  // Path to logo in public folder
            alt="Logo with Name"
            height="40" // Adjust the height according to your design
          />
        </Navbar.Brand>

        {/* Right side: Language Selector */}
        <Nav className="ml-auto">
          <DropdownButton
            id="language-selector"
            title={currentLang} // Display the current language
            onSelect={(eventKey) => handleLanguageChange(eventKey)} // Change language
            variant="secondary"
          >
            <Dropdown.Item eventKey="en">English</Dropdown.Item>
            <Dropdown.Item eventKey="es">Español</Dropdown.Item>
            <Dropdown.Item eventKey="pt">Português</Dropdown.Item>
            <Dropdown.Item eventKey="fr">Français</Dropdown.Item>
            <Dropdown.Item eventKey="pl">Polski</Dropdown.Item>
          </DropdownButton>
        </Nav>
      </Container>
    </Navbar>
  );
};

export default Header;
