import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { VALID_LANGUAGES } from './constant';

const AboutUsPage = () => {
    const {lang} = useParams();
    const {i18n } = useTranslation();
    const {t} = useTranslation();
    const navigate = useNavigate();

    useEffect(() => {
        if (lang && VALID_LANGUAGES.includes(lang)) {
              i18n.changeLanguage(lang)
        } else {
          navigate('/en');
        }
      }, [i18n, lang, navigate])

    // Helper function to convert \n to line breaks
    const renderDescriptionWithLineBreaks = (text: string) => {
        return text.split("\n").map((line, index) => (
        <React.Fragment key={index}>
            {line}
            <br />
        </React.Fragment>
        ));
    };    
    return (
        <>
        <Helmet>
          <title>{t(`about_us.title`)}</title>
          <meta name="description" content={t(`about_us.meta_description`)} />
          <meta name="title" content={t(`about_us.meta_title`)} />
          <meta name="keywords" content={t(`about_us.meta_keywords`)} />
          <link rel="alternate" hrefLang='en' href="https://spiin.me/en/about-us"/>
          <link rel="alternate" hrefLang='es' href="https://spiin.me/es/about-us"/>
          <link rel="alternate" hrefLang='pt' href="https://spiin.me/pt/about-us"/>
          <link rel="alternate" hrefLang='fr' href="https://spiin.me/fr/about-us"/>
          <link rel="alternate" hrefLang='pl' href="https://spiin.me/pl/about-us"/>
          {/* Google Analytics script */}
          <script async src="https://www.googletagmanager.com/gtag/js?id=G-01WZPXST5F"></script>
          <script>
            {`
              window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}
              gtag('js', new Date());
              gtag('config', 'G-01WZPXST5F');
            `}
          </script>          
        </Helmet>
        <div style={{ textAlign: "center", marginTop: "50px" }}>
  
            <h1>{t("about_us.title")}</h1>
  
            {/* Description with left alignment */}
            <div style={{ textAlign: "left", marginTop: "20px" , marginLeft: "10%", marginRight: "10%"}}>
              {renderDescriptionWithLineBreaks(t(`about_us.faq_content`))}
            </div>
        </div>
      </>
    )
}
export default AboutUsPage